.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.floating-times {
  top: 30%;
  left: 25%;
}

Input, textarea, select {
  font-size: 0.875rem !important;
}

th,
th > span {
  font-size: 0.75rem;
  text-transform: capitalize !important;
}

thead{
  border: 1px solid white;
  //white-space: nowrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
